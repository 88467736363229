<template>
  <div class="flex flex-col gap-y-2">

    <div id="sales-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="order-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_from"
                labelAddon="Od"
                :markDirty="true"
                :error="saveErrors.date_from"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_to"
                labelAddon="Do"
                :markDirty="true"
                :error="saveErrors.date_to"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">
              <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-0 sm:flex-1" v-model="activeFilters.insurer_id" :nullOption="false" label="TU" :options="insurerListExtended" />

              <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-0 sm:flex-1" v-model="activeFilters.employee_id" label="Agent" :options="employeeSimpleList" />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div id="order-adv-filters-2" class="flex flex-wrap items-center gap-3">
        <div class="flex flex-wrap items-center gap-3">
          <div class="w-full 2xl:w-auto flex flex-wrap sm:flex-nowrap items-center gap-x-3 py-1">
            <label for="in-general" title="WZNOWIONE = wznowione w dowolnym TU lub (domyślnie) wznowione w tym samym TU">
              <input
                id="in-general"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="activeFilters.in_general"
              > wznowione w dowolnym TU
            </label>
            <label for="only-active" title="tylko pracownicy ze statusem 'Aktywny'">
              <input
                id="only-active"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="activeFilters.only_active"
              > tylko aktywni
            </label>
            <label for="nonero" title="Tylko pracownicy z przypisem > 0">
              <input
                id="nonero"
                type="checkbox"
                true-value="1" false-value="0"
                v-model="activeFilters.nonzero"
              > niezerowe
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
      </div>
    </div>
    <div id="sales-report-content" class="p-3 pt-1 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-col">
        <span class="font-semibold">Raport sprzedaży (przypis: nowe/wznowione) wg daty wystawienia polis</span>
      </div>

      <hr class="dark:border-gray-600 -mx-3">

      <div class="flex flex-wrap gap-x-5 gap-y-1 mt-2">

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">przypis razem</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ reportSum.all | moneyPL }}</span>
        </div>

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">dynamika razem</span>
          <span class="px-3 py-1 rounded-r" :class="sumAllDynamic < 0 ? 'bg-red-600' : 'bg-green-600'">{{ sumAllDynamic | percentPL }}%</span>
        </div>

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">przypis nowe</span>
          <span class="px-3 py-1 rounded-r bg-indigo-500 dark:bg-indigo-900">{{ reportSum.new | moneyPL }}</span>
        </div>

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">przypis wznowione</span>
          <span class="px-3 py-1 rounded-r bg-purple-500 dark:bg-purple-900">{{ reportSum.renewed | moneyPL }}</span>
        </div>

      </div>

      <div class="flex flex-wrap">
        <div class="w-full xl:w-4/5 2xl:w-3/5 pr-5">
          <div class="hidden lg:flex border-b border-gray-400 dark:border-gray-600 font-semibold">
            <div class="flex-grow">Agent</div>
            <div class="w-28 text-right">ogółem</div>
            <div class="w-20 text-right" title="dynamika rok do roku"><FontAwesomeIcon icon="chart-line" /></div>
            <div class="w-28 text-right">nowe</div>
            <div class="w-12 text-right">szt</div>
            <div class="w-24 text-right">%</div>
            <div class="w-28 text-right">wznowione</div>
            <div class="w-12 text-right">szt</div>
            <div class="w-24 text-right">%</div>
            <div class="w-12 text-center" title="eksport polis wszystkich pracowników do pliku CSV">
              <a v-if="reportSum.all > 0 && showAdmin" class="tw--link" :href="exportCsvUrl" target="_blank">
                <FontAwesomeIcon icon="cloud-download-alt" />
              </a>
              <FontAwesomeIcon v-else class="text-gray-300 dark:text-gray-700" icon="cloud-download-alt" />
            </div>
          </div>
          <div v-if="filteredReport.length === 0" class="mt-2 italic text-muted">
            brak pozycji spełniających wybrane kryteria
          </div>
          <div v-for="(row, index) in filteredReport"
            :key="index"
            class="flex flex-col lg:flex-row border-b border-gray-900 lg:border-gray-400 dark:border-gray-400 lg:dark:border-gray-700 border-solid lg:border-dashed"
          >
            <div class="flex-grow flex justify-between lg:justify-start items-center">
              <div>{{ row.employee_name }}</div>
              <a v-if="Number(row.sum_all) > 0" class="lg:hidden tw--link" :href="`${exportCsvUrl}${row.id}`" target="_blank">
                <FontAwesomeIcon icon="cloud-download-alt" size="lg" />
              </a>
            </div>
            <div class="flex-shrink-0 w-full lg:w-28 flex justify-between lg:justify-end">
              <div class="block lg:hidden text-muted">ogółem</div><div>{{ row.sum_all | moneyPL }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-20 flex justify-between lg:justify-end">
              <div class="block lg:hidden text-muted">dynamika</div>
              <div :class="{'text-red-600': Number(row.sales_dynamic) < 0, 'text-green-600': Number(row.sales_dynamic) > 0}">{{ row.sales_dynamic | percentPL }}%</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-28 flex justify-between lg:justify-end border-t border-gray-600 dark:border-gray-400 border-dashed lg:border-t-0">
              <div class="block lg:hidden text-muted">nowe</div><div>{{ row.sum_new | moneyPL }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-12 flex justify-between lg:justify-end">
              <div class="block lg:hidden text-muted">polis</div><div>{{ row.quantity_new }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-24 flex justify-between lg:justify-end">
              <div class="block lg:hidden text-muted">% przypisu</div><div>{{ row.percent_new | percentPL }}%</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-28 flex justify-between lg:justify-end border-t border-gray-600 dark:border-gray-400 border-dashed lg:border-t-0">
              <div class="block lg:hidden text-muted">wznowione</div><div>{{ row.sum_renewed | moneyPL }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-12 flex justify-between lg:justify-end">
              <div class="block lg:hidden text-muted">polis</div><div>{{ row.quantity_renewed }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-24 flex justify-between lg:justify-end">
              <div class="block lg:hidden text-muted">% przypisu</div><div>{{ row.percent_renewed | percentPL }}%</div>
            </div>
            <div class="hidden lg:flex flex-shrink-0 w-full lg:w-12 justify-between lg:justify-center items-center">
              <a v-if="Number(row.sum_all) > 0" class="tw--link" :href="`${exportCsvUrl}${row.id}`" target="_blank">
                <FontAwesomeIcon icon="cloud-download-alt" />
              </a>
              <FontAwesomeIcon v-else class="text-gray-300 dark:text-gray-700" icon="cloud-download-alt" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { errorHandler } from '../../../mixins/errorHandler';
import moment from 'moment';
import EximaService from '../../../services/EximaService';

export default {
  name: 'excSalesReport',
  mixins: [errorHandler],
  data() {
    return {
      report: [],
      activeFilters: {
        insurer_id: 'wszystkie',
        employee_id: '',
        date_from: '',
        date_to: '',
        in_general: '1',
        only_active: '1',
        nonzero: '1',
      },
      /**
       * Poniższe dwie zmienne są zdublowane z actiVeFilters, ponieważ działają w przeglądarce
       * i będą ustawiane tylko podczas getReport() aby nie renderować widoku po każdym kliknięciu
       * i aby były jednoznaczne i zgodne z $route.query
       */
      onlyActive: true,
      nonzero: true,
      saveErrors: {},
    };
  },
  computed: {
    ...mapGetters(['currentUserType']),
    ...mapGetters('products', [
      'insurerList',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    insurerListExtended() {
      return [{id: 'wszystkie', name: 'wszystkie'}, ...this.insurerList];
    },
    reportSum() {
      return this.report.reduce((sumObj, currObj) => {
        return {
          all: sumObj.all + Number(currObj.sum_all),
          new: sumObj.new + Number(currObj.sum_new),
          renewed: sumObj.renewed + Number(currObj.sum_renewed),
          prevPeriod: sumObj.prevPeriod + Number(currObj.sum_prev_period),
        };
      }, { all: 0, new: 0, renewed: 0, prevPeriod: 0 });
    },
    sumAllDynamic() {
      return Math.round((this.reportSum.all - this.reportSum.prevPeriod) * 10000 / this.reportSum.prevPeriod) / 100;
    },
    filteredReport() {
      return this.report.filter(obj => {
        if (this.onlyActive && Number(obj.status_id) !== 1) return false;
        if (this.nonzero && Number(obj.sum_all) === 0) return false;
        return true;
        // return (onlyActive && nonzero) || (onlyActive && Number(obj.sum_all) !== 0) || (nonzero && Number(obj.status_id) === 1) || (Number(obj.sum_all) !== 0 && Number(obj.status_id) === 1);
      });
    },
    exportCsvUrl() {
      let params = '?';
      Object.keys(this.$route.query).forEach(key => {
        if (key !== 'employee_id') {
          params = `${params}${key}=${this.$route.query[key]}&`;
        }
      });
      return `${process.env.VUE_APP_API_URL}/api/exclusive-reports/exima/employee-sales-report-to-csv/${params}employee_id=`;
    },
  },
  mounted() {
    // if (this.currentUserType !== 'root' && this.currentUserType !== 'admin') {
    //   this.$router.push({name: 'Reports'});
    //   this.$store.dispatch('notifications/add', { type: 'danger', message: 'Brak uprawnień do modułu' });
    // } else {
      this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
      if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.init();
    // }
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      EximaService.getSalesReport(this.activeFilters)
       .then((response) => {
          this.report = response.data.result;
          this.onlyActive = Number(this.activeFilters.only_active) === 1;
          this.nonzero = Number(this.activeFilters.nonzero) === 1;
          this.setUrl();
          this.report01 = response.data.report01;
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    prevMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
    },
    setToday() {
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
    },
    nextMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'EximaSalesReport', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.activeFilters.insurer_id = 'wszystkie';
      this.activeFilters.in_general = '1';
      this.activeFilters.only_active = '1';
      this.activeFilters.nonzero = '1';
      this.getReport();
    },
  },
};
</script>
